// import React from "react";
import { Dropdown, Menu } from "antd";
import { useNavigate } from "react-router-dom";

import { Custombutton, Custom, CustomMenu } from "./styled";
//@ts-ignore
import { ReactComponent as PlusBig } from "../../../../assets/images/priorities/PlusBig.svg";
//@ts-ignore
import { ReactComponent as DownWard } from "../../../../assets/images/priorities/DownWard.svg";

const ActionDropdown = () => {
  const navigate = useNavigate();

  const handleClick = (type: string) => () => {
    navigate(`?create=${type}`);
  }

  const menu = (
    <CustomMenu style={{ top: "0px", width: "91px !important", padding: 4 }}>
      <Menu.Item key="0" onClick={handleClick("Habit")}>Habit</Menu.Item>
      <Menu.Item key="1" onClick={handleClick("Action")}>Action</Menu.Item>
      <Menu.Item key="2" onClick={handleClick("Task")}>Task</Menu.Item>
    </CustomMenu>
  );

  return (
    <Custom>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        overlayStyle={{ width: "8px" }}
      >
        <Custombutton id="react-joyride-step1">
          <PlusBig />
          Add Priority
          <DownWard />
        </Custombutton>
      </Dropdown>
    </Custom>
  );
};

export default ActionDropdown;
