import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { InputField } from "elements";
import { Popover } from "antd";

//@ts-ignore
import { ReactComponent as HourglassIcon } from "assets/images/priorities/hourglass.svg";

//@ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";
//@ts-ignore
import { ReactComponent as QuestionIcon } from "assets/images/priorities/QuestionIcon.svg";

import {
  Wrapper,
  Head,
  BodyTime,
  Title,
  CloseBtn,
  Row,
  Col,
  TextWithPopover,
  Text,
  QuestionIcn,
  DirectImpactStyle,
} from "./styled";

//@ts-ignore
import useDeviceType from "../../../../Hooks/ResponsiveHook";
import { updateAction } from "Redux/APM/CreateAction/action";
import { updateTask } from "Redux/APM/CreateTask/action";
import { useDispatch } from "react-redux";
import WeeklyDurationPopover from "components/PriorityApp/SimpleView/WeeklyDurationPopover";
import DelegationPopover from "../DelegationPopup";
import PopoverCustom from "../PopoverCustom";

interface TimePopoverProps {
  id: any;
  inline?: boolean;
  isTask?: boolean;
  delegation_time: number;
  management_time: number;
  onClose?: () => void;
}

interface IState {
  duration: {
    h: string;
    m: string;
  };
  myTime: {
    h: string;
    m: string;
  };
}

const TimePopover = forwardRef(
  (
    {
      onClose,
      id,
      inline,
      isTask,
      delegation_time,
      management_time,
    }: TimePopoverProps,
    ref
  ) => {
    const screenSize = useDeviceType();

    const dispatch = useDispatch();
    const [hoveredComponent, setHoveredComponent] = useState<string>("");

    const [values, setValues] = useState<IState>({
      duration: {
        h: "",
        m: "",
      },
      myTime: {
        h: "",
        m: "",
      },
    });

    const [showError, setShowError] = useState(false);

    useImperativeHandle(ref, () => ({
      submit: () => {
        onSubmit();
      },
    }));

    useEffect(() => {
      setValues({
        duration: {
          h: isNaN(Math.floor(delegation_time / 60))
            ? ""
            : Math.floor(delegation_time / 60).toString(),
          m: isNaN(delegation_time % 60)
            ? ""
            : (delegation_time % 60).toString(),
        },
        myTime: {
          h: isNaN(Math.floor(management_time / 60))
            ? ""
            : Math.floor(management_time / 60).toString(),
          m: isNaN(management_time % 60)
            ? ""
            : (management_time % 60).toString(),
        },
      });

      setShowError(true);
    }, [delegation_time, management_time]);

    const handleTimeChange =
      (field: string, unit: string) => (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value.length) {
          setValues({
            ...values,
            [field]: {
              // @ts-ignore
              ...values[field],
              [unit]: "",
            },
          });

          return;
        }

        let value = parseInt(e.target.value);

        if (isNaN(value)) {
          return;
        }

        if (unit === "h") {
          value = Math.min(999, Math.max(0, value));
        } else if (unit === "m") {
          value = Math.min(59, Math.max(0, value));
        }

        setValues({
          ...values,
          [field]: {
            // @ts-ignore
            ...values[field],
            [unit]: value,
          },
        });
      };

    const handleBlur = () => {
      if (!inline) return;

      if (!values.duration.h && !values.duration.m) {
        // Set border color to red if duration fields are empty
        const newValues = {
          ...values,
          duration: {
            ...values.duration,
            h: values.duration.h || "", // Preserve existing value or set empty string
            m: values.duration.m || "", // Preserve existing value or set empty string
          },
        };

        setValues(newValues);

        return;
      }

      onSubmit();
    };

    const onSubmit = () => {
      const formData = new FormData();

      if (!isTask) formData.append("prioritized_activity_id", id);

      if (values.duration.h || values.duration.m) {
        const duration = {
          h: parseInt(values.duration.h || "0"),
          m: parseInt(values.duration.m || "0"),
        };

        formData.append(
          "estimated_duration",
          (duration.h * 60 + duration.m).toString()
        );
      } else {
        setShowError(true); // Show error if both fields are empty or 0
        return;
      }

      if (/* (values.myTime.h || values.myTime.m) &&  */ !isTask) {
        const myTime = {
          h: parseInt(values.myTime.h || "0"),
          m: parseInt(values.myTime.m || "0"),
        };

        // Adjust duration if myTime is greater than duration
        if (
          myTime.h * 60 + myTime.m >
          parseInt(values.duration.h || "0") * 60 +
            parseInt(values.duration.m || "0")
        ) {
          const duration = {
            h: myTime.h,
            m: myTime.m,
          };

          formData.append(
            "estimated_duration",
            (duration.h * 60 + duration.m).toString()
          );
        }

        formData.append("my_time", (myTime.h * 60 + myTime.m).toString());
      }

      if (isTask)
        dispatch(updateTask(formData, id, () => {}, { notify: false }));
      else
        dispatch(
          updateAction(formData, () => {}, { notify: false, loader: false })
        );
    };
    const handleMouseEnter = (componentName: string) => {
      setHoveredComponent(componentName);
    };

    const handleMouseLeave = () => {
      setHoveredComponent("");
    };

    return (
      <Wrapper $inline={!!inline} $mobile={screenSize !== "desktop"}>
        {inline ? null : (
          <Head>
            <Title>
              <HourglassIcon />
              <span>Time</span>
            </Title>
            <CloseBtn onClick={() => onClose && onClose()}>
              <CloseIcon />
            </CloseBtn>
          </Head>
        )}

        <BodyTime $inline={!!inline}>
          <Col id="react-joyride-step5">
            {inline ? (
              <TextWithPopover>
                <Text>Estimated Weekly Duration*</Text>

                <Popover
                  align={{ offset: [20, 0] }}
                  // @ts-ignore
                  content={WeeklyDurationPopover}
                  title={null}
                  placement="bottomRight"
                  overlayClassName="custom_popover"
                  overlayStyle={{ zIndex: 9999 }}
                >
                  <QuestionIcon />
                </Popover>
              </TextWithPopover>
            ) : (
              <Text>Estimated Duration*</Text>
            )}
            <Row>
              <InputField
                /* @ts-ignore */
                style={{ width: 70, paddingRight: 12 }}
                value={values.duration.h}
                size="xs"
                placeholder="-"
                suffix={"h"}
                error={
                  showError &&
                  (!values.duration.h || values.duration.h === "0") &&
                  (!values.duration.m || values.duration.m === "0")
                }
                onChange={handleTimeChange("duration", "h")}
                onBlur={handleBlur}
              />
              <InputField
                /* @ts-ignore */
                style={{ width: 70 }}
                value={values.duration.m}
                size="xs"
                placeholder="-"
                suffix={"m"}
                error={
                  showError &&
                  (!values.duration.h || values.duration.h === "0") &&
                  (!values.duration.m || values.duration.m === "0")
                }
                onChange={handleTimeChange("duration", "m")}
                onBlur={handleBlur}
              />
            </Row>
          </Col>
          {isTask ? null : (
            <Col>
              <TextWithPopover>
                <Text>Delegation overhead</Text>
                {inline ? (
                  <Popover
                    align={{ offset: [20, 0] }}
                    // @ts-ignore
                    content={DelegationPopover}
                    title={null}
                    placement="bottomRight"
                    overlayClassName="custom_popover"
                    overlayStyle={{ zIndex: 9999 }}
                  >
                    <QuestionIcon />
                  </Popover>
                ) : null}
              </TextWithPopover>

              <Row>
                <InputField
                  /* @ts-ignore */
                  style={{ width: 70, paddingRight: 12 }}
                  value={values.myTime.h}
                  size="xs"
                  placeholder="-"
                  suffix={"h"}
                  /* error={
                    showError &&
                    (!values.myTime.h || values.myTime.h === "0") &&
                    (!values.myTime.m || values.myTime.m === "0")
                  } */
                  onChange={handleTimeChange("myTime", "h")}
                  onBlur={handleBlur}
                />
                <InputField
                  /* @ts-ignore */
                  style={{ width: 70 }}
                  value={values.myTime.m}
                  size="xs"
                  placeholder="-"
                  suffix={"m"}
                  /* error={
                    showError &&
                    (!values.myTime.h || values.myTime.h === "0") &&
                    (!values.myTime.m || values.myTime.m === "0")
                  } */
                  onChange={handleTimeChange("myTime", "m")}
                  onBlur={handleBlur}
                />
              </Row>
            </Col>
          )}
        </BodyTime>
      </Wrapper>
    );
  }
);

export default TimePopover;
